import React from 'react';
import styles from './404.module.css';
import { Helmet } from 'react-helmet';

export default () => (
  <div className={styles.textwrapper}>
    <Helmet title="404" />
    <div className={styles.title} data-content="404">
      404
    </div>

    <div className={styles.subtitle}>Oops, la page que vous cherchez n'existe pas.</div>

    <div className={styles.buttons}>
      <a className={styles.button} href="/">
        Retour à l'accueil
      </a>
    </div>
  </div>
);
